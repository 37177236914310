import React, { useEffect, useState, useRef, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import cinemaBg from '@assets/pictures/cinema-banner.png';
import cinema from '@assets/pictures/cinema.png';
import whiteArrowDown from '@assets/pictures/svgs/white-arrow-down.svg';
import map from '@assets/pictures/svgs/map.svg';
import Slider from 'react-slick';
import AuditoriumCard from './partials/AuditoriumCard.jsx';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import { fetchCinemas } from '../store/movies/movieActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import Spinner from './partials/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import Nav from 'react-bootstrap/Nav';
import EventImg1 from '@assets/pictures/RIZ05053.JPG';
// import EventImg2 from '@assets/pictures/RIZ04736.JPG';
import EventImg2 from '@assets/pictures/sample-bg.png';
import EventImg3 from '@assets/pictures/RIZ04064.JPG';
import EventVideo1 from '@assets/pictures/video_cinema.MP4';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import AdvertiseForm from './AdvertiseForm.jsx';
const Events = ({ t }) => {
  const dispatch = useDispatch();
  //   const cinemas = useSelector((state) => state.cinemas);
  //   const { cinemasLoading, allCinemas } = cinemas;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const history = useHistory();
  // const [cinemaDetails, setCinemaDetails] = useState(false);
  // if (cinemaDetails === true) {
  //   history.push("/cinema-details");
  // }
  // useEffect(() => {
  //   if (!cinemas?.allCinemas) {
  //     dispatch(fetchAllCinemas());
  //   }
  //   window.scrollTo(0, 0);
  // }, []);
  //   useEffect(() => {
  //     dispatch(fetchAllCinemas());
  //   }, [dispatch]);
  useEffect(() => window.scrollTo(0, 0), []);
  const heroSectionObj = {
    arr: [1],
    backgroundImg: EventImg2,
    // title: t('cinema_listing.maintitle'),
    // msg: t('cinema_listing.maindesc'),
  };
  const [key, setKey] = useState('home');

  return (
    <>
      <style>
        {`    .banner-image
            {
            width: 100%;
            background-image: url('@/assets/pictures/untitled-27.jpg');
            height: 405px;
            // background-color: purple;
            background-position: center;
            }`}
      </style>
      <div>
        <div className="container-fluid align_new">
          {/* <BreadCrumb firstHalf={`${t('Home')} >`} secondHalf={t('Location')} /> */}
          <CenteredTitle firstHalf={'Updates'} secondHalf={''} />
        </div>
        <div className="px-0">
          <section>
            <div className="banner-image">
              <img src={EventImg2} className="banner-image"></img>
            </div>
          </section>
          <section className="container-fluid container-xl mt-3 location_wrapper">
            <div className="">
              <p className="awu_sub_text">
                Star Cinemas hosts a variety of events throughout the year. These
                events range from movie screenings and premieres to themed parties
                and interactive game nights. Some of the events that Star Cinemas
                hosts include:
              </p>
              <Row>
                <div
                  className={`${
                    isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                  }`}
                >
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex justify-content-center align-items-center ${
                      isMobile ? 'mb-5' : 'mb-5'
                    }`}
                  >
                    {/* <img src={AdvertiseImg1} className="img-fluid awu_img" /> */}
                    {/* <video src={EventVideo1} className="img-fluid awu_img"></video> */}
                    <video
                      // width="320" height="240"
                      className="img-fluid awu_img"
                      controls
                    >
                      <source src={EventVideo1} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Col>

                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                  >
                    <h4 className="font-weight-bold">
                      1.Bed Rest Premiere : A momentous occasion!{' '}
                    </h4>
                    <p className="awu_sub_text">
                      The audience was in admiration while Kris Fade decorated the
                      premiere of Bed Rest. With the galvanic energy and influencers
                      who honoured the event, Bed Rest premiere was widely
                      recognized.
                    </p>
                  </Col>
                </div>

                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                  <h4 className="font-weight-bold">
                    2. Saturday Night Screening :- Glitz, Glam & an absolute blast!
                  </h4>
                  <p className="awu_sub_text">
                    The Saturday Night premiere had celebrities and influencers
                    gathered for the exclusive Dubai screening. The occasion was a
                    noteworthy celebration, and at Star Cinemas, it had a festive
                    atmosphere.
                  </p>
                </Col>

                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`d-flex justify-content-center align-items-center ${
                    isMobile ? 'mb-5' : 'mb-5'
                  }`}
                >
                  <img src={EventImg1} className="img-fluid awu_img" />
                </Col>

                <div
                  className={`${
                    isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                  }`}
                >
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex justify-content-center align-items-center ${
                      isMobile ? 'mb-5' : 'mb-5'
                    }`}
                  >
                    <img src={EventImg3} className="img-fluid awu_img" />
                  </Col>
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                  >
                    <h4 className="font-weight-bold">
                      3. Iratta Dubai Promotion:- A star studded evening at Star
                      Cinemas!{' '}
                    </h4>
                    <p className="awu_sub_text">
                      Joju George attended the Iratta movie premiere alongside his
                      co-stars Anjali, Srinda, Srikant Murali, and Sabumon. The
                      public was looking forward to seeing their favourite artists,
                      and they were thrilled to see them up close. Photo sessions
                      and interaction with the talents brought cheers alongside the
                      presence of special screening for the Media fraternity. This
                      was indeed a memorable promotion for Star Cinemas.
                    </p>
                  </Col>
                </div>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Events);
