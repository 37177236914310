export const API_VERSION = "v1.0";
const HOST_URL = `wowcinemas.brij.tech`;
const LIVEBASEURL = `https://wowcinemas-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const WOW_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImU2ZDExNTNkLTdjYzctNDdhZC04ZmQ3LTQ4MDBlNDE5MzhlNSIsImlhdCI6MTcyMDYwMTEyNX0.H1FRnD7HC4kqIGL9CtDSsojb9GxVBbCOwHGMJmHh7hE`;
export const TOKEN = WOW_TOKEN;
