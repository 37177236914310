// import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';

import Image4 from '@assets/pictures/GOLD CLASS.jpg';

import clockImg from '@assets/pictures/svgs/clock_poster.svg';
import calenderImg from '@assets/pictures/svgs/calendar-poster.svg';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef } from 'react';
// import MovieCardNew from './partials/MovieCardNew';

// import useIsMobile from '../../customHooks/useIsMobile';
import moment from 'moment';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';
import {
  useGetNowShowingQuery,
  useGetUpcomingQuery,
} from '@store/rtk/rtk.movieLists';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import TrailerIcon from '@assets/pictures/svgs/play_new_1.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import useIsMobile from '@src/customHooks/useIsMobile';

const VipExp = ({ t, isUpcoming = false }) => {
  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id } = selected_language;
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });
  // console.log(todaysScreeningsMovies?.data, 'todaysScreeningsMovies?.data');

  const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();

  //  const dilbyFlteredData = (id) => {
  //   return id > 0;
  //   };

  let advanceData = todaysScreeningsMovies?.data?.filter((movie) => {
    let result = !(
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()))
    );

    return result;
  });
  // console.log(advanceData, 'advance');

  let advanceDolbyArr = [];

  advanceData?.map((x) => {
    x.languages_with_translations.map((y) => {
      if (y.movie_experience_id == 2) {
        advanceDolbyArr = [...advanceDolbyArr, x];
      }
    });
  });

  // console.log(advanceDolbyArr, 'advanceDlbyArr');
  // console.log(uniqueComingSoonMovies, 'uniqueComingSoonMovies');

  let nowshowingdata = todaysScreeningsMovies?.data?.filter((movie) => {
    let result =
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()));

    return result;
  });

  let nowshowing_dolbyresultArr = [];

  nowshowingdata?.map((x) => {
    x.languages_with_translations.map((y) => {
      if (y.movie_experience_id == 2) {
        nowshowing_dolbyresultArr = [...nowshowing_dolbyresultArr, x];
      }
    });
  });

  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };
  let upcomingDolbyArray = [];

  uniqueComingSoonMovies?.data?.map((x) => {
    x.languages_with_translations.map((y) => {
      if (y.movie_experience_id == 2) {
        upcomingDolbyArray = [...upcomingDolbyArray, x];
      }
    });
  });
  // console.log(nowshowing_dolbyresultArr, 'nowshowing_dolbyresultArr');

  return (
    <>
      <div className="align_new">
        <ModalVideo
          autoplay
          channel={videoChannel}
          isOpen={isOpen}
          videoId={currentVideoId}
          onClose={() => setOpen(false)}
        />
        <div className="left_right_align">
          <CenteredTitle
            //   firstHalf={`${t('Privacy Policy')}`}
            firstHalf={`${'Experience Details'}`}
            secondHalf={''}
          />
        </div>
        <div className="">
          <img src={Image4} className="img-fluid experience_banner"></img>
        </div>

        <div className="container-fluid" style={{ marginTop: '30px' }}>
          <section className="row">
            <article className="col-xl-12 mt-3">
              <h2 className="font-weight-bold sub_title_exp">
                About The Experience
              </h2>
              <p style={{ padding: isMobile ? '5px' : '20px', textAlign: 'left' }}>
                Enter a new world of sound with Dolby Atmos®. A leap forward from
                surround sound, it transports you into the story with moving audio
                that fills the cinema and flows all around you to deliver the
                premier sound of entertainment.
              </p>
            </article>
          </section>

          <section className="">
            <div className="row">
              <article className="col-xl-12 mt-3">
                <h2 className="font-weight-bold sub_title_exp">
                  Movies Showing in Vip
                </h2>
              </article>
            </div>
            {nowshowing_dolbyresultArr.length > 0 ||
            upcomingDolbyArray.length > 0 ||
            advanceDolbyArr.length > 0 ? (
              <section
                className="movie_n_fests_new"
                style={{ padding: !isMobile ? '20px' : '' }}
              >
                <div className="movie_n_fests_grid">
                  {/* Now Showing Div Starts Here */}

                  {nowshowing_dolbyresultArr.map((props) => {
                    return (
                      <div className="d-block parent_poster_wrapper">
                        <div
                          className={`${'movie_card_new'} `}
                          // onMouseEnter={() => setIsShown(true)}
                          // onMouseLeave={() => setIsShown(false)}
                          // style={{ width: isUpcoming ? '150%' : '' }}
                          onClick={() =>
                            history.push(
                              `/now-showing-booking/${props?.movie_id}/${props?.content_lang_id}/`,
                            )
                          }
                        >
                          {/*has-tooltip previous class for on hover button*/}
                          {/* Image */}

                          <figure className="">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  props?.languageSpecificImages ||
                                    props?.altMovieContent,
                                  lang_id,
                                  'artwork',
                                ) || dummyPoster
                              }
                              className="img-fluid poster_exp_img"
                              alt={t('alt.Movie Poster')}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyPosterStatic;
                              }}
                            />

                            <div class="details">
                              {props?.trailer_array?.map(
                                (trailer, trailerIndex) => (
                                  <img
                                    key={trailerIndex[0]}
                                    src={TrailerIcon}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if (trailer?.mt_link?.includes('youtube')) {
                                        setVideoChannel('youtube');
                                        setCurrentVideoId(
                                          trailer?.mt_link?.split('=')[1],
                                        );
                                      }
                                      if (trailer?.mt_link?.includes('vimeo')) {
                                        setVideoChannel('vimeo');
                                        setCurrentVideoId(
                                          trailer?.mt_link
                                            ?.split('/')
                                            .pop()
                                            .split('.')?.[0],
                                        );
                                      }
                                      setOpen(true);
                                    }}
                                  />
                                ),
                              )}
                              <button className="book_now_btn">
                                {!isUpcoming ? 'Book Now' : 'More'}
                              </button>
                            </div>
                          </figure>

                          {/*Tool tip on hover */}
                          {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {props?.mrrdr_release_date && t('From')} <br/>
            {props?.mrrdr_release_date && moment(props?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

                          <div className="movie_poster_details">
                            <div
                              className={`d-flex justify-content-between ${
                                isMobile ? 'mb-2' : ''
                              }`}
                            >
                              <div className="responsive_font_movie_title">
                                {getLangSpecificAttribute(
                                  props?.altMovieContent,
                                  lang_id,
                                  'mc_title',
                                ) ||
                                  props?.movie_title ||
                                  props?.mc_title}
                                <br />
                              </div>
                              <div className="responsive_font_movie_title">
                                {props?.rating || props?.rating}
                              </div>
                            </div>
                            <div className="">
                              <span
                                className="genre_poster"
                                style={{ wordBreak: isMobile ? 'break-all' : '' }}
                              >
                                {props?.genre_name}
                              </span>
                            </div>

                            <div
                              className="d-flex align-items-end"
                              style={{
                                gap: isAdvance || isUpcoming ? '3px' : '10px',
                              }}
                            >
                              <img
                                src={
                                  isAdvance || isUpcoming ? calenderImg : clockImg
                                }
                                className="clock_img"
                              />
                              {isAdvance || isUpcoming ? (
                                <>
                                  <div className="time_poster">
                                    {moment(props?.mrrdr_release_date).format(
                                      'MMM Do',
                                    )}
                                  </div>

                                  <div className="time_poster">
                                    <img
                                      src={isAdvance || isUpcoming ? clockImg : '|'}
                                      className="clock_img"
                                    />{' '}
                                    {`${Math.floor(
                                      props?.mrrdr_runtime / 60,
                                    )} hrs ${
                                      props?.mrrdr_runtime % 60 != 0
                                        ? (props?.mrrdr_runtime % 60) + ' mins'
                                        : ''
                                    }`}
                                  </div>
                                </>
                              ) : (
                                <div className="time_poster">
                                  {`${Math.floor(props?.mrrdr_runtime / 60)} hrs ${
                                    props?.mrrdr_runtime % 60 != 0
                                      ? (props?.mrrdr_runtime % 60) + ' mins'
                                      : ''
                                  }`}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* Now Showing Div Ends Here */}

                  {/* {Advance Div Starts Here} */}
                  {advanceDolbyArr.map((propsAdvance) => {
                    return (
                      <div className="d-block parent_poster_wrapper">
                        <div className="advance_tag">
                          <span>Advance</span>
                        </div>

                        <div
                          className={`${'movie_card_new'} `}
                          // onMouseEnter={() => setIsShown(true)}
                          // onMouseLeave={() => setIsShown(false)}
                          // style={{ width: isUpcoming ? '150%' : '' }}
                          onClick={() =>
                            history.push(
                              `/now-showing-booking/${propsAdvance?.movie_id}/${propsAdvance?.content_lang_id}/`,
                            )
                          }
                        >
                          {/*has-tooltip previous class for on hover button*/}
                          {/* Image */}

                          <figure className="">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  propsAdvance?.languageSpecificImages ||
                                    propsAdvance?.altMovieContent,
                                  lang_id,
                                  'artwork',
                                ) || dummyPoster
                              }
                              className="img-fluid poster_exp_img"
                              alt={t('alt.Movie Poster')}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = dummyPosterStatic;
                              }}
                            />

                            <div class="details">
                              {propsAdvance?.trailer_array?.map(
                                (trailer, trailerIndex) => (
                                  <img
                                    key={trailerIndex[0]}
                                    src={TrailerIcon}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      if (trailer?.mt_link?.includes('youtube')) {
                                        setVideoChannel('youtube');
                                        setCurrentVideoId(
                                          trailer?.mt_link?.split('=')[1],
                                        );
                                      }
                                      if (trailer?.mt_link?.includes('vimeo')) {
                                        setVideoChannel('vimeo');
                                        setCurrentVideoId(
                                          trailer?.mt_link
                                            ?.split('/')
                                            .pop()
                                            .split('.')?.[0],
                                        );
                                      }
                                      setOpen(true);
                                    }}
                                  />
                                ),
                              )}
                              <button className="book_now_btn">{'Book Now'}</button>
                            </div>
                          </figure>

                          <div className="movie_poster_details">
                            <div
                              className={`d-flex justify-content-between ${
                                isMobile ? 'mb-2' : ''
                              }`}
                            >
                              <div className="responsive_font_movie_title">
                                {getLangSpecificAttribute(
                                  propsAdvance?.altMovieContent,
                                  lang_id,
                                  'mc_title',
                                ) ||
                                  propsAdvance?.movie_title ||
                                  propsAdvance?.mc_title}
                                <br />
                              </div>
                              <div className="responsive_font_movie_title">
                                {propsAdvance?.rating || propsAdvance?.rating}
                              </div>
                            </div>
                            <div className="">
                              <span
                                className="genre_poster"
                                style={{ wordBreak: isMobile ? 'break-all' : '' }}
                              >
                                {propsAdvance?.genre_name}
                              </span>
                            </div>

                            <div className="d-flex align-items-end justify-content-between">
                              <img src={calenderImg} className="clock_img" />

                              <>
                                <div className="time_poster">
                                  {moment(propsAdvance?.mrrdr_release_date).format(
                                    'MMM Do',
                                  )}
                                </div>

                                <div className="time_poster">
                                  <img src={clockImg} className="clock_img" />{' '}
                                  {`${Math.floor(
                                    propsAdvance?.mrrdr_runtime / 60,
                                  )} hrs ${
                                    propsAdvance?.mrrdr_runtime % 60 != 0
                                      ? (propsAdvance?.mrrdr_runtime % 60) + ' mins'
                                      : ''
                                  }`}
                                </div>
                              </>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* {Advance Div Ends Here} */}
                  {/* Upcoming Div Starts Here */}
                  {upcomingDolbyArray.map((propsUpcoming) => {
                    return (
                      <div
                        className={`${'movie_card_new'} `}
                        // onMouseEnter={() => setIsShown(true)}
                        // onMouseLeave={() => setIsShown(false)}
                        // style={{ width: isUpcoming ? '150%' : '' }}
                        onClick={() =>
                          history.push(
                            `/movie-details/${propsUpcoming?.movie_id}/${propsUpcoming?.content_lang_id}/3`,
                          )
                        }
                      >
                        {/*has-tooltip previous class for on hover button*/}
                        {/* Image */}

                        <figure className="">
                          <img
                            src={
                              getLangSpecificAttribute(
                                propsUpcoming?.languageSpecificImages ||
                                  propsUpcoming?.altMovieContent,
                                lang_id,
                                'artwork',
                              ) || dummyPoster
                            }
                            className="img-fluid poster_exp_img"
                            alt={t('alt.Movie Poster')}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = dummyPosterStatic;
                            }}
                          />

                          <div class="details">
                            {propsUpcoming?.trailer_array?.map(
                              (trailer, trailerIndex) => (
                                <img
                                  key={trailerIndex[0]}
                                  src={TrailerIcon}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (trailer?.mt_link?.includes('youtube')) {
                                      setVideoChannel('youtube');
                                      setCurrentVideoId(
                                        trailer?.mt_link?.split('=')[1],
                                      );
                                    }
                                    if (trailer?.mt_link?.includes('vimeo')) {
                                      setVideoChannel('vimeo');
                                      setCurrentVideoId(
                                        trailer?.mt_link
                                          ?.split('/')
                                          .pop()
                                          .split('.')?.[0],
                                      );
                                    }
                                    setOpen(true);
                                  }}
                                />
                              ),
                            )}
                            <button className="book_now_btn">{'More'}</button>
                          </div>
                        </figure>

                        {/*Tool tip on hover */}
                        {/*{
          !!isUpcoming && (
          <p className="tooltip-new">

       <button className="btn_hov">
         {propsUpcoming?.mrrdr_release_date && t('From')} <br/>
            {propsUpcoming?.mrrdr_release_date && moment(propsUpcoming?.mrrdr_release_date).format('DD MMM YYYY')}
            </button>
          </p>
          )}*/}

                        <div className="movie_poster_details">
                          <div
                            className={`d-flex justify-content-between ${
                              isMobile ? 'mb-2' : ''
                            }`}
                          >
                            <div className="responsive_font_movie_title">
                              {getLangSpecificAttribute(
                                propsUpcoming?.altMovieContent,
                                lang_id,
                                'mc_title',
                              ) ||
                                propsUpcoming?.movie_title ||
                                propsUpcoming?.mc_title}
                              <br />
                            </div>
                            <div className="responsive_font_movie_title">
                              {propsUpcoming?.rating || propsUpcoming?.rating}
                            </div>
                          </div>
                          <div className="">
                            <span
                              className="genre_poster"
                              style={{ wordBreak: isMobile ? 'break-all' : '' }}
                            >
                              {propsUpcoming?.genre_name}
                            </span>
                          </div>

                          <div
                            className="d-flex align-items-end"
                            style={{
                              gap: '3px',
                            }}
                          >
                            <img src={calenderImg} className="clock_img" />

                            <>
                              <div className="time_poster">
                                {moment(propsUpcoming?.mrrdr_release_date).format(
                                  'MMM Do',
                                )}
                              </div>

                              <div className="time_poster">
                                <img src={clockImg} className="clock_img" />{' '}
                                {`${Math.floor(
                                  propsUpcoming?.mrrdr_runtime / 60,
                                )} hrs ${
                                  propsUpcoming?.mrrdr_runtime % 60 != 0
                                    ? (propsUpcoming?.mrrdr_runtime % 60) + ' mins'
                                    : ''
                                }`}
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* Upcoming Div Ends Here */}
                </div>
              </section>
            ) : (
              <div className="col-xl-12 mt-3">No Movies Found</div>
            )}

            {/* <div class="flex-container">
              <div className="dolby-movie">
                <div>
                  <img
                    src={Image1}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image2}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
              <div>
                <div>
                  <img
                    src={Image3}
                    alt="img"
                    height={300}
                    width={250}
                    style={{ borderRadius: '25px' }}
                  ></img>
                </div>
              </div>
            </div> */}
          </section>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(VipExp);
