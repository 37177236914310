import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import CountryCodeDropdown from '../CountryCodeDropdown';

import back from '@assets/pictures/svgs/back-modal.svg';
import close from '@assets/pictures/svgs/close.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  login,
  register,
  loginViaOtp,
  numberRegex,
  emailRegex,
  forgotPassword,
  startTimer,
  resetTimer,
  clearOTP,
} from '../../../store/auth/authActions';
import LoginOtpPopup from './LoginOtpPopup.jsx';
import OtpPopup from './OtpPopup.jsx';
import { withNamespaces } from 'react-i18next';
import showPasswordIcon from '@assets/pictures/svgs/show-password.svg';
import hidePasswordIcon from '@assets/pictures/svgs/hide-password.svg';
import useIsMobile from '../../../customHooks/useIsMobile';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
  logout,
  setShowLoginPopup,
  guestLogout,
  fetchGuestHistory,
  updateUserLang,
} from '@store/auth/authActions';

const LoginPopup = (props) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneNoError, setPhoneNoError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [phone_no, setPhone_no] = useState(``);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const [registerNew, setRegisterNew] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [loginField, setLoginField] = useState(``);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [submittedviaOtp, setSubmittedviaOtp] = useState(false);
  const [loginOtpPopup, setLoginOtpPopup] = useState(false);
  const t = props.t;
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [type, setType] = useState(null);
  const [loadingResendOtp, setLoadingResendOtp] = useState(false);
  const [registerConditionsCheck, setRegisterConditionsCheck] = useState(false);
  const [registerConditionsCheckError, setRegisterConditionsCheckError] =
    useState(false);

  const state = useSelector((state) => state);
  const {
    auth: {
      loginOtpError,
      timer: { minutes, seconds },
      timerCompleted,
    },
  } = state;

  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id, lang_name, iso_2 } = selected_language;

  const [allowResendLogin, setAllowResendLogin] = useState(
    minutes * 60 + seconds > 0 ? false : true,
  );
  const [allowResend, setAllowResend] = useState(false);
  const [field, setField] = useState(``);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [ogDate, setOgDate] = useState(new Date());

  const onErrorHandling = () => {
    if (loginError === 'Password is incorrect') {
      setOtpPopup(true);
      props.closeFunc(false);
    } else {
      setRegisterNew(true);
      setSubmitted(false);
    }
  };

  let loginFieldFromParent = useSelector((s) => s.auth.loginField);
  const onClickResendLogin = () => {
    if (timerCompleted) {
      // setAllowResendLogin(false);
      setLoadingResendOtp(true);
      dispatch(
        loginViaOtp({
          loginField: loginField || loginFieldFromParent,
          callback: setLoadingResendOtp,
          successCallback: () => {
            dispatch(resetTimer());
            dispatch(startTimer());
            // setAllowResendLogin(false);
            // setInterval(() => {
            //   setAllowResendLogin(true);
            // }, (minutes * 60 + seconds) * 1000);
          },
        }),
      );
    }
  };

  const onClickResend = () => {
    if (timerCompleted) {
      // setAllowResend(false);
      setLoadingResendOtp(true);
      dispatch(
        forgotPassword({
          field,
          callback: setLoadingResendOtp,
          successCallback: () => {
            // setAllowResend(false);
            dispatch(resetTimer());
            dispatch(startTimer());
            // setInterval(() => {
            //   setAllowResend(true);
            // }, (minutes * 60 + seconds) * 1000);
          },
        }),
      );
    }
  };
  const isLoginWithOTPClicked = useRef(false);

  const onClickLoginViaOTP = () => {
    setType('otp');
    setSubmittedviaOtp(true);
    let count = 0;

    if (isLoginWithOTPClicked.current === true) {
      return;
    }

    if (!registerNew && loginField === ``) {
      count++;
    }

    if (count > 0) {
      return;
    }
    isLoginWithOTPClicked.current = true;
    setLoadingOtp(true);
    // setSubmittedviaOtp(false);
    setTimeout(() => {
      isLoginWithOTPClicked.current = false;
    }, 5 * 1000);
    dispatch(
      loginViaOtp({
        loginField,
        callback: setLoadingOtp,
        successCallback: () => {
          onCloseModal();
          setLoginOtpPopup(true);
          dispatch(resetTimer());
          dispatch(startTimer());
          // setAllowResendLogin(false);
          // setTimeout(
          //   () => setAllowResendLogin(true),
          //   (minutes * 60 + seconds) * 1000
          // );
        },
      }),
    ).then((response) => {
      if (response.status === false) {
        setSubmittedviaOtp(false);
      }
    });
  };
  useEffect(() => {
    // setRegisterNew(registerNew);
  }, []);
  const onFormSubmit = (e) => {
    e.preventDefault();
    setType('email');
    setSubmitted(true);

    setRegisterConditionsCheckError(false);
    if (registerNew && !registerConditionsCheck) {
      setSubmitted(true);
      setRegisterConditionsCheckError(true);
      return;
    }

    let count = 0;
    if (registerNew && email && emailRegex(email)) {
      setEmailError(false);
    }
    if (registerNew && email && !emailRegex(email)) {
      setEmailError(true);
      count++;
    }
    if (registerNew && firstName !== '') {
      setFirstNameError(false);
    }
    if (registerNew && firstName === '') {
      setFirstNameError(true);
      count++;
    }
    if (registerNew && lastName !== '') {
      setLastNameError(false);
    }
    if (registerNew && lastName === '') {
      setLastNameError(true);
      count++;
    }
    if (registerNew && phone_no !== '') {
      setPhoneNoError(false);
    }
    if (registerNew && (phone_no === '' || !countryId)) {
      setPhoneNoError(true);
      count++;
    }
    // if (registerNew && !selectedDate) {
    //   setDobError(true);
    //   count++;
    // }
    if (!registerNew && loginField === ``) {
      count++;
    }
    if (password !== '') {
      setPasswordError(false);
    }
    if (password === '') {
      setPasswordError(true);
      count++;
    }
    if (count > 0) {
      return;
    }
    setLoading(true);
    setSubmitted(false);
    if (registerNew) {
      let payload = {
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        phone_no,
        country_id: countryId,
        callback: setLoading,
      };
      if (selectedDate) payload.dob = moment(selectedDate).format('YYYY-MM-DD');

      dispatch(register(payload)).then((status) => {
        if (status) {
          //window.location.reload()
          onCloseModal();
          props.closeFunc(false);
        }
      });
    } else {
      dispatch(
        login({ loginField, password, callback: setLoading, countryId }),
      ).then((status) => {
        if (status) {
          // window.location.reload()
          onCloseModal();
          props.closeFunc(false);
        }
      });
    }
  };
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, registerError } = loginDetails;
  useEffect(() => {
    if (loggedInUser) {
      props.closeFunc(false);
    }
  }, [loggedInUser]);

  useEffect(() => {
    setEmail('');
    setLoginField('');
    setFirstName('');
    setLastName('');
    setPassword('');
    setPhone_no('');
    setCountryId(null);
    setCountryMobCode(null);
  }, [registerNew]);
  const [isNumber, setisNumber] = useState(false);

  useEffect(() => {
    let ifNumber = numberRegex(loginField);
    if (!ifNumber) setCountryId(null);

    setisNumber(ifNumber);
  }, [loginField]);

  const onCloseModal = () => {
    setLoginField('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setPassword('');
    setPhone_no('');
    setCountryId(null);
    setCountryMobCode(null);
    props.closeFunc(false);
    setSubmitted(false);
    setRegisterNew(false);
    setCalendarPopup(false);
    setSelectedDate(null);
    setOgDate(new Date());
    setRegisterConditionsCheck(false);
    dispatch(setShowLoginPopup(false));
    // if (registerNew) {
    //   registerNew(true);
    // }
  };

  return (
    <>
      <LoginOtpPopup
        allowResendLogin={allowResendLogin}
        setAllowResendLogin={setAllowResendLogin}
        show={loginOtpPopup}
        closeFunc={() => {
          setLoginOtpPopup(false);
          props.closeFunc(false);
        }}
        onClickResendLogin={onClickResendLogin}
        loadingResendOtp={loadingResendOtp}
        loginModalCloseFunc={onCloseModal}
      />
      <OtpPopup
        onClickResend={onClickResend}
        allowResend={allowResend}
        setAllowResend={setAllowResend}
        show={otpPopup}
        closeFunc={() => {
          dispatch(clearOTP());
          setOtpPopup(false);
          props.closeFunc(true);
        }}
        field={field}
        setField={setField}
        loadingResendOtp={loadingResendOtp}
        loginModalCloseFunc={onCloseModal}
      />
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="login-popup"
        centered
        onHide={() => {
          !isMobile && onCloseModal();
        }}
      >
        <section className="wrapper container-fluid">
          {
            <img
              // src={props.register ? back : close}
              src={close}
              alt=""
              className="back-btn-main __to_right"
              onClick={onCloseModal}
            />
          }
          <div className="d-flex register_tabs">
            <div
              className={`w-100 tabs py-2 ${!registerNew && 'active'}`}
              onClick={() => setRegisterNew(false)}
            >
              {t(`header-footer-menu.Sign in`)}
            </div>
            <div
              className={`w-100 tabs py-2 ${registerNew && 'active'}`}
              onClick={() => setRegisterNew(true)}
            >
              {/* {t(`login_form.Login`)} */}
              {t(`header-footer-menu.signup`)}
            </div>
          </div>

          <p className="title">
            {/* {registerNew ? t("Register") : t("Login")}{" "}
            {t("login_form.placeholders.email-mobile")} */}
          </p>

          <form className="user-form" onSubmit={onFormSubmit}>
            {!registerNew && (
              <>
                <label>
                  <span> {t('login_form.placeholders.email-mobile')}</span>
                  <div className="d-flex">
                    {isNumber && (
                      <CountryCodeDropdown
                        className="country_code_drop mr-2"
                        onSelectCountry={({ country_id, country_mob_code }) => {
                          setCountryId(country_id);
                          setCountryMobCode(country_mob_code);
                        }}
                      />
                    )}
                    <input
                      type="text"
                      placeholder={t(`Enter_email_or_mobile`)}
                      value={loginField}
                      onChange={(e) => setLoginField(e.target.value)}
                    />
                  </div>
                </label>

                <p className="error">
                  {(submitted || submittedviaOtp) && loginField === ''
                    ? t('field_required')
                    : submitted &&
                      !isNumber &&
                      !emailRegex(loginField) &&
                      t('enter-valid-email')}
                </p>
              </>
            )}
            {registerNew && (
              <>
                <label>
                  <span>{t('login_form.Phone Number')} </span>
                  <div className="d-flex">
                    <CountryCodeDropdown
                      className="country_code_drop mr-2"
                      onSelectCountry={({ country_id, country_mob_code }) => {
                        setCountryId(country_id);
                        setCountryMobCode(country_mob_code);
                      }}
                    />
                    <input
                      type="number"
                      placeholder={t(
                        'login_form.placeholders.Enter your phone number',
                      )}
                      value={phone_no}
                      min="10"
                      onChange={(e) => setPhone_no(e.target.value)}
                    />
                  </div>
                </label>
                {/* {submitted && phone_no === "" && ( */}
                <p className="error">
                  {submitted &&
                    phone_no === '' &&
                    t('login_form.error_messages.Phone number is required')}
                </p>
                {/* )} */}
              </>
            )}
            {registerNew && (
              <>
                <label>
                  <span> {t('login_form.Email')} </span>
                  <input
                    type="email"
                    placeholder={t('login_form.placeholders.Enter your email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                {/* {submitted && email === "" && ( */}
                <p className="error">
                  {submitted &&
                    (emailError
                      ? t('enter-valid-email')
                      : email === '' && t('field_required'))}
                </p>
              </>
            )}
            {registerNew && (
              <>
                <label>
                  <span> {t('login_form.DateOfBirth')} </span>
                  <div className="position-relative">
                    <input
                      className="fields"
                      type="date"
                      id="start"
                      name="dateofbirth"
                      placeholder={t('login_form.DateOfBirth')}
                      min="1920-01-01"
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) => {
                        setSelectedDate(e?.target?.value);
                      }}
                    />
                  </div>
                  <p className="error">
                    {submitted &&
                      !selectedDate &&
                      dobError &&
                      t('Please enter event date')}
                  </p>
                </label>
              </>
            )}
            {/* )} */}
            <div className="d-flex">
              <div className="pr-2">
                {registerNew && (
                  <>
                    <label>
                      <span> {t('login_form.First Name')} </span>
                      <input
                        type="text"
                        placeholder={t(
                          'login_form.placeholders.Enter your first name',
                        )}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </label>
                    {/* {submitted && firstName === "" && ( */}
                    <p className="error">
                      {submitted &&
                        firstName === '' &&
                        t('login_form.error_messages.First name is required')}
                    </p>
                    {/* )} */}
                  </>
                )}
              </div>

              <div className="pl-2">
                {registerNew && (
                  <>
                    <label>
                      <span>{t('login_form.Last Name')} </span>
                      <input
                        type="text"
                        placeholder={t(
                          'login_form.placeholders.Enter your last name',
                        )}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </label>
                    {/* {submitted && lastName === "" && ( */}
                    <p className="error">
                      {submitted &&
                        lastName === '' &&
                        t('login_form.error_messages.Last name is required')}
                    </p>
                    {/* )} */}
                  </>
                )}
              </div>
            </div>
            <label className="position-relative">
              <span> {t('login_form.Password')} </span>
              <input
                type={showLoginPassword ? 'text' : 'password'}
                autocomplete="new-password"
                placeholder={t('login_form.placeholders.Enter your password')}
                value={password}
                min="4"
                onChange={(e) => setPassword(e.target.value)}
              />
              {!showLoginPassword && (
                <img
                  alt=""
                  width="30"
                  height="30"
                  className="show-password-icon"
                  src={showPasswordIcon}
                  onClick={() => setShowLoginPassword((x) => !x)}
                />
              )}
              {showLoginPassword && (
                <img
                  alt=""
                  width="30"
                  height="30"
                  className="show-password-icon"
                  src={hidePasswordIcon}
                  onClick={() => setShowLoginPassword((x) => !x)}
                />
              )}
            </label>
            {/* {submitted && password === "" && ( */}
            {/* {submitted && password === "" && !submittedviaOtp && ( */}
            <p className="error">
              {submitted &&
                password === '' &&
                type === 'email' &&
                t('login_form.error_messages.Password is required')}
            </p>

            {registerNew && (
              <>
                <div className="agree-check ml-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="exampleRadios"
                    id="exampleRadios1"
                    checked={registerConditionsCheck}
                    style={{ width: 'unset', height: 'unset' }}
                    onChange={(e) => setRegisterConditionsCheck(e.target.checked)}
                  />
                  <label
                    className="form-check-label pt-1 pl-1"
                    htmlFor="exampleRadios1"
                  >
                    {t('I agree to all')}{' '}
                    <a href="/tnc" target="_blank">
                      {t('Terms & Conditions')}
                    </a>
                  </label>
                </div>
                <p className="error">
                  {submitted && !registerConditionsCheck && (
                    <>
                      {t('You must agree to all Terms & Conditions')}
                      <br /> <br />
                    </>
                  )}
                </p>
              </>
            )}

            {loginError && !registerNew && type === 'email' && password !== '' ? (
              <p className="error">
                {t(loginError)},{' '}
                <span style={{ cursor: `pointer` }} onClick={onErrorHandling}>
                  {loginError === 'Password is incorrect' ? (
                    <u>{t(`please click here to change password`)}</u>
                  ) : (
                    <u>{t(`please click here to sign up`)}</u>
                  )}
                </span>
              </p>
            ) : null}

            {registerError && registerNew ? (
              <p className="error">{t(registerError)}</p>
            ) : null}

            {loginOtpError && type === 'otp' && (
              <p className="error">
                {t(loginOtpError)},{' '}
                <span
                  style={{ cursor: `pointer` }}
                  onClick={() => {
                    setRegisterNew(true);
                    setSubmitted(false);
                  }}
                >
                  <u>{t(`please click here to sign up`)}</u>
                </span>
              </p>
            )}

            {registerNew ? (
              <button
                type="submit"
                className={`btn-main mt-3 ${
                  registerNew && loading && 'loader-text'
                }`}
              >
                {t('login_form.Confirm')}
              </button>
            ) : (
              <button
                type="submit"
                className={`btn-main mt-3 ${loading && 'loader-text'}`}
              >
                {t('login_form.Login')}
              </button>
            )}

            <div className="login-popup__actions">
              {!registerNew && (
                <button
                  type="button"
                  className="btn-basic"
                  onClick={() => {
                    setOtpPopup(true);
                    props.closeFunc(false);
                  }}
                >
                  {t(`login_form.forgot-password`)}
                </button>
              )}

              {/* {!registerNew && (
              <button
                onClick={() => {
                  setOtpPopup(true);
                  props.closeFunc(false);
                }}
                className={`btn-main mt-3`}
              >
                {t(`login_form.forgot-password`)}
              </button>
            )} */}
              {!registerNew && (
                <button
                  type="button"
                  onClick={onClickLoginViaOTP}
                  className={`btn-basic ${loadingOtp ? 'loader-text' : ''}`}
                >
                  {t(`Login via OTP`)}
                </button>
              )}
            </div>
          </form>
        </section>
      </Modal>
    </>
  );
};

export default withNamespaces()(LoginPopup);
